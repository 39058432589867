import React from 'react';
import { graphql, Link } from 'gatsby';
import SEO from '../components/SEO';
import Layout from '../components/Layout';
import Call from '../components/Call';
import Icon from '../components/Icon';
import CustomerOverview from '../components/CustomerOverview';

const Home = ({ location, data }) => {
  const index = data.index;
  const intro = data.intro;
  const portfolio = data.portfolio;
  const site = data.site.siteMetadata;
  const portfolioEntries = data.portfolioEntries.edges;
  const jobPostings = data.jobPostings.edges;

  const [portfolioHeader, portfolioFirstParagraph, ...portfolioRest] =
    `${portfolio.html}`.split('\n');

  const introImageClasses = `intro-image ${
    intro.frontmatter.image_absolute && 'intro-image-absolute'
  } ${intro.frontmatter.image_hide_on_mobile && 'intro-image-hide-mobile'}`;

  return (
    <Layout bodyClass="page-home">
      <SEO title={site.title} url={location.href} />

      {/* <div className="index mt-10 mb-3">
        <div className="container">
          <div className="row justify-content-start">
            <div className="col-12">
              <div dangerouslySetInnerHTML={{ __html: index.html }} />
            </div>
          </div>
        </div>
      </div> */}

      <div className="intro mb-3">
        <div className="container mb-12 mt-12 pb-12 pt-12 showcase">
          <div className="row justify-content-start">
            {/* <div className="col-12 col-md-7 col-lg-6 order-2 order-md-1"> */}
            <div className="col-12">
              <div dangerouslySetInnerHTML={{ __html: intro.html }} />
              <Call showButton />
            </div>
            {/* {intro.frontmatter.image && (
              <div className="col-12 col-md-5 col-lg-6 order-1 order-md-2 position-relative">
                <img
                  alt={intro.frontmatter.title}
                  className={introImageClasses}
                  src={intro.frontmatter.image}
                />
              </div>
            )} */}
          </div>
        </div>
      </div>

      <div className="index mt-10 mb-3">
        <div className="container">
          <div className="row justify-content-start">
            <div className="col-12">
              <div dangerouslySetInnerHTML={{ __html: portfolioHeader }} />
            </div>
          </div>
          <div className="row justify-content-start">
            <div className="col-12">
              <div
                dangerouslySetInnerHTML={{ __html: portfolioFirstParagraph }}
              />
            </div>
          </div>
          <div className="row justify-content-start">
            <div className="col-12 col-lg-6">
              <div
                dangerouslySetInnerHTML={{ __html: portfolioRest.join('') }}
              />
            </div>
            <div className="col-12 col-lg-6">
              <div
                className="row align-items-center"
                style={{ height: '100%' }}
              >
                <img
                  alt={portfolio.frontmatter.title}
                  src={portfolio.frontmatter.image}
                  style={{ width: '100%', height: 'auto' }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {portfolioEntries.length > 0 && (
        <div className="strip strip-grey">
          <div className="container pt-6 pb-6 pt-md-10 pb-md-10">
            <div className="row justify-content-center">
              {portfolioEntries.map(({ node }) => (
                <div key={node.id} className="col-12 col-md-6 col-lg-4 mb-2">
                  <div className="portfolio-column">
                    <Icon
                      iconName={node.icon}
                      style={{ fontSize: '40px', paddingBottom: '10px' }}
                    />
                    <h2 className="portfolio-column-title">{node.title}</h2>
                    {node.items && node.items.length > 0 && (
                      <div className="col-12 mb-2 mt-2 portfolio-column-content">
                        <ul>
                          {node.items.map((childNode) => (
                            <li>{childNode.title}</li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}

      {jobPostings.length > 0 && (
        <div className="strip">
          <div className="container pt-6 pb-6">
            <div className="col-12">
              <div className="row">
                <h3>
                  Du begeisterst dich für Softwareentwicklung, hast Erfahrung
                  mit Microsoft Technologien und suchst nach einer neuen
                  Herausforderung? Sieh dir unsere offenen Positionen an!
                </h3>
              </div>
            </div>
            <div className="row justify-content-start pt-2">
              {jobPostings.map(({ node }) => (
                <div key={node.id} className="col-12 col-md-6">
                  <div className="service service-summary">
                    <div className="service-content">
                      <h2 className="service-title">
                        <Link to={node.fields.slug}>
                          {node.frontmatter.title_short}
                        </Link>
                      </h2>
                      <p>
                        {node.frontmatter.teaser_text}{' '}
                        <Link to={node.fields.slug}>Mehr</Link>
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}

      <div className="strip-grey">
        <div className="container pt-6">
          <div className="col-12">
            <div className="row">
              <h2>Unsere Kunden</h2>
            </div>
          </div>
        </div>
        <div className="container pb-6">
          <div className="col-12">
            <div className="row">
              <CustomerOverview />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    index: markdownRemark(fileAbsolutePath: { regex: "/content/index.md/" }) {
      html
      frontmatter {
        image
        title
      }
      excerpt
    }
    intro: markdownRemark(
      fileAbsolutePath: { regex: "/content/index/intro.md/" }
    ) {
      html
      frontmatter {
        image
        image_absolute
        image_hide_on_mobile
      }
      excerpt
    }
    portfolio: markdownRemark(
      fileAbsolutePath: { regex: "/content/index/portfolio.md/" }
    ) {
      html
      frontmatter {
        image
        image_absolute
        image_hide_on_mobile
      }
      excerpt
    }
    jobPostings: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/careers/.*/" } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            title_short
            teaser_text
            date
          }
        }
      }
    }
    portfolioEntries: allPortfolioJson {
      edges {
        node {
          id
          title
          icon
          items {
            title
          }
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`;

export default Home;
